<template>
  <div class="dealer">
    <Pagenationtopimg />
    <Pagenationtop :pagenation_title="pagenation_title" path="dealer"/>
    <div class="container">
      <div class="btn-container">
          <button
          v-for="(item,index) in main_data"
          :key="item.index"
          @click="goTo(index)">
              {{item.title}}
          </button>
      </div>
      <div class="content" v-for="(item,index) in main_data" 
      :id="index"
      :key="item.index">
        <div class="ps-title">
        ┃{{ item.title }}
        </div>
        <div class="container">
          <div class="sub-container" v-for="list in item.data" :key="list.index">
            <p class="ps-title">
              {{ list.title }}
            </p>
            <p class="address">
              {{ list.address }}
            </p>
            <hr />
            <p class="connection">
              {{ list.tel }}
            </p>
            <p class="connection">
              {{ list.fax }}
            </p>
            <p class="connection">
              {{ list.email }}
            </p>
            <div class="googlemap">
              <div class="google-container">
                <a :href="list.mapurl" target="_blank">
                </a>
                <img src="../../public/img/dealer/googlemap.jpg" alt="">
                <p>查看地圖</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagenationtopimg from "../components/pagenationimg/index.vue";
import Pagenationtop from '../components/pagenationtop/index.vue'
import qs from 'qs'
export default {
  data(){
    return{
        pagenation_title:[
          '經銷商'
        ],
        title_data:null,
        main_data:null
    }
  },
  created(){
    this.$store.commit('openOverlay')
  },
  components: {
    Pagenationtopimg,
    Pagenationtop,
  },
  mounted(){
    this.getData()
  },
  methods:{
    getData(){
      this.axios.post('/api/dealer/getdealer_catalog',qs.stringify({
        lang:this.$store.state.lang
      }))
      .then(response => (
        console.log(response),
        this.main_data = response.data.result,
        this.$store.commit('closeOverlay')
      ))
    },
    goTo(index){
        document.getElementById(index).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
        });
    },
  }
};
</script>